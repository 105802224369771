<template>
  <v-dialog v-model="showMenuEntryDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <MenuEntryForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          refForm="menuEntryDialogForm"
          ref="menuEntryDialogForm"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showMenuEntryDialog = false">{{
          $t("Close")
        }}</v-btn>
        <v-spacer />
        <v-btn class="mainAccent lighten-3" dark @click="resetForm">{{
          $t("Reset")
        }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t("Submit") }}</v-btn>
      </v-card-actions>
    </v-card>
    <Loading :visible="isLoading" />
  </v-dialog>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import MenuEntryForm from "./Form";
import NotificationMixin from "../../mixins/NotificationMixin";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";

const servicePrefix = "MenuEntry";

const { mapFields } = createHelpers({
  getterType: "menuEntry/getField",
  mutationType: "menuEntry/updateField",
});

export default {
  name: "MenuEntryDialog",
  servicePrefix,
  mixins: [NotificationMixin],
  components: {
    Loading,
    MenuEntryForm,
  },
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true,
    },
    handleCreated: {
      type: Function,
      required: false,
    },
    handleUpdated: {
      type: Function,
      required: false,
    },
    menuEntryItem: {
      type: Object,
      required: false,
    },
    parent: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      item: {},
      updateMessage: this.$t("successfullyUpdated"),
      createMessage: this.$t("successfullyCreated"),
      headers: [
        { text: this.$i18n.t("title"), value: "title" },
        { text: this.$i18n.t("enabled"), value: "enabled" },
      ],
    };
  },
  computed: {
    ...mapFields([
      "error",
      "isLoading",
      "created",
      "violations",
      "updated",
      "byId",
    ]),
    ...mapGetters("menuEntry", ["find"]),
    showMenuEntryDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapActions("menuEntry", {
      create: "create",
      update: "update",
      resetData: "resetData",
      retrieve: "load",
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.$refs.menuEntryDialogForm.$v.item.$model;
        if (this.handleUpdated && model["@id"]) {
          this.update(model);
        }
        if (this.handleCreated && !model["@id"]) {
          if (this.parent) {
            model['parent'] = this.parent['@id'];
          }
          this.create(model);
        }
      }
    },
    reset() {
      this.$refs.menuEntryDialogForm.$v.$reset();
    },
    resetForm() {
      this.item = { ...this.menuEntryItem };
      if (this.$refs["menuEntryDialogForm"]) {
        this.$refs.menuEntryDialogForm.$v.$reset();
      }
    },
    isValidForm() {
      this.$refs.menuEntryDialogForm.$v.$touch();
      return !this.$refs.menuEntryDialogForm.$v.$invalid;
    },
  },
  watch: {
    menuEntryItem() {
      this.item = { ...this.menuEntryItem };
      this.resetForm();
    },
    created(created) {
      if (!created) {
        return;
      }
      this.showMessage(this.createMessage);
      if (this.handleCreated) {
        this.handleCreated(this.created);
        this.showMenuEntryDialog = false;
      }
    },
    updated() {
      this.showMessage(this.updateMessage);
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
        this.showMenuEntryDialog = false;
      }
    },
    error(message) {
      message && this.showError(message);
    },
  },
  created() {
    this.item = { ...this.menuEntryItem };
  },
};
</script>
