<template>
  <div>
    <v-row justify="space-around">
      <v-icon @click="handleShow" class="mr-1" small v-if="showEnabled()">mdi-eye</v-icon>
      <v-icon @click="handleDownload" class="mr-1" small v-if="downloadEnabled()">mdi-download</v-icon>
      <v-icon @click="handleEdit" small v-if="editEnabled()">mdi-pencil</v-icon>
      <v-icon @click="handleSend" small v-if="sendEnabled()">mdi-send</v-icon>
      <v-icon @click="confirmDelete = true" small v-if="deleteEnabled()">mdi-delete</v-icon>
    </v-row>
    <ConfirmDelete
      :handle-delete="handleActionDelete"
      :visible="confirmDelete"
      @close="confirmDelete = false"
      v-if="deleteEnabled"
    />
  </div>
</template>

<script>
import ConfirmDelete from './ConfirmDelete';

export default {
  name: 'ActionCell',
  components: {
    ConfirmDelete
  },
  data() {
    return {
      confirmDelete: false
    };
  },
  props: {
    handleShow: {
      type: Function,
      required: false
    },
    enableShow: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleEdit: {
      type: Function,
      required: false
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleDelete: {
      type: Function,
      required: false
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleDownload: {
      type: Function,
      required: false
    },
    enableDownload: {
      type: Boolean,
      required: false,
      default: () => true
    },
    handleSend: {
      type: Function,
      required: false
    },
    enableSend: {
      type: Boolean,
      required: false,
      default: () => true
    },
  },
  methods: {
    showEnabled() {
      return (this.handleShow && this.enableShow);
    },
    editEnabled() {
      return (this.handleEdit && this.enableEdit);
    },
    deleteEnabled() {
      return (this.handleDelete && this.enableDelete);
    },
    downloadEnabled() {
      return (this.handleDownload && this.enableDownload);
    },
    sendEnabled() {
      return (this.handleSend && this.enableSend);
    },
    handleActionDelete(...args) {
      if (this.deleteEnabled()) {
        this.handleDelete(...args);
      }
    }
  },
};
</script>
