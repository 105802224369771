<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"/>
    <MenuForm
      :errors="violations"
      :values="item"
      :loading="isLoading"
      refForm="updateForm"
      ref="updateForm"
      :handle-submit="onSendForm"
    />

    <p class="headline">{{ $t("menuEntries") }}</p>
    <v-btn color="primary" @click="createOrEditMenuEntryHandler()">
      {{ $t("createMenuEntry") }}
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="menuItems"
      :items-per-page="15"
      :loading="isLoading"
      :loading-text="$t('Loading...')"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isDescending"
      class="elevation-1"
      item-key="@id"
      v-if="item.menuItems && item.menuItems.length"
    >
      <template slot="item.enabled" slot-scope="{ item }">
        <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
        <v-icon v-else>mdi-close-circle</v-icon>
      </template>
      <ActionCell
        :handle-delete="() => deleteHandler(props.item)"
        :handle-edit="() => createOrEditMenuEntryHandler(props.item)"
        slot="item.action"
        slot-scope="props"
      ></ActionCell>
    </v-data-table>
    <MenuEntryDialog
      v-model="showMenuEntryDialogForm"
      :menu-entry-item="menuEntryFormItem"
      :handle-created="handleCreated"
      :handle-updated="handleUpdated"
      :show-handle="showMenuEntryDialogForm"
      :title="menuEntryDialogTitle"
      :parent="item"
      @close="menuEntryDialogControl(true)"
    />
    <Loading :visible="isLoading || deleteLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import MenuForm from "../../components/menu/Form.vue";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import UpdateMixin from "../../mixins/UpdateMixin";
import CreateMixin from "../../mixins/CreateMixin";
import ActionCell from "../../components/ActionCell";
import MenuEntryDialog from "../../components/menuEntry/Dialog";
import MenuEntryMixin from "../../mixins/MenuEntryMixin";

const servicePrefix = "Menu";

export default {
  name: "MenuUpdate",
  servicePrefix,
  mixins: [CreateMixin, UpdateMixin, MenuEntryMixin],
  data() {
    return {
      updateMessage: this.$t("successfullyUpdated"),
      deleteMessage: this.$t("successfullyDeleted"),
      updateRoute: null,
      headers: [
        {text: this.$i18n.t("title"), value: "title"},
        {text: this.$i18n.t("entryOrder"), value: "entryOrder"},
        {text: this.$i18n.t("enabled"), value: "enabled"},
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
        },
      ],
      showMenuEntryDialogForm: false,
      menuEntryFormItem: {},
      menuEntryDialogTitle: this.$t("AddMenuEntry"),
      sortBy: "entryOrder",
      isDescending: false,
    };
  },
  components: {
    Loading,
    Toolbar,
    MenuForm,
    ActionCell,
    MenuEntryDialog,
  },
  computed: {
    ...mapFields("menu", {
      deleteLoading: "isLoading",
      isLoading: "isLoading",
      error: "error",
      updated: "updated",
      violations: "violations",
      totalItems: "totalItems",
      resetList: "resetList",
    }),
    ...mapFields("menuEntry", {
      deleted: "deleted",
    }),
    ...mapGetters("menu", ["find"]),
    menuItems() {
      return this.item.menuItems
        .map((menuItem) => (typeof menuItem === "string") ? this.resolveMenuEntry(menuItem) : menuItem)
        .filter((e) => e);
    },
  },
  methods: {
    ...mapActions("menu", {
      deleteItem: "del",
      retrieve: "load",
      update: "update",
      getPage: "fetchAll",
    }),
    ...mapActions("menuEntry", {
      deleteMenuEntryItem: "del",
    }),
    editHandler(item) {
      this.$router.push({
        name: `${this.$options.servicePrefix}Update`,
        params: {id: item["@id"]},
      });
      this.retrieve(item["@id"]);
    },
    deleteHandler(item) {
      this.deleteMenuEntryItem(item);
    },
    deleteWatcher(deleted) {
      if (!deleted) {
        return;
      }

      this.item.menuItems.splice(
        this.item.menuItems.indexOf(deleted["@id"]),
        1
      );
      this.showMessage(this.deleteMessage);
    },
    menuEntryDialogControl(close = false) {
      this.showMenuEntryDialogForm = !close;
    },
    handleCreated(item) {
      this.item.menuItems.push(item["@id"]);
    },
    handleUpdated() {
    },
    createOrEditMenuEntryHandler(item) {
      this.menuEntryFormItem = item ?? {};
      if (item) {
        this.menuEntryDialogTitle = this.$t("EditMenuEntry");
      } else {
        this.menuEntryDialogTitle = this.$t("AddMenuEntry");
      }
      this.menuEntryDialogControl();
    },
  },
};
</script>
