<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            outlined
            :error-messages="titleErrors"
            :label="$t('name')"
            @blur="$v.item.title.$touch()"
            @input="$v.item.title.$touch()"
            required
            v-model="item.title"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" sm="4">
          <v-select
            :error-messages="typeErrors"
            :label="$t('type')"
            required
            :items="typeValues"
            v-model="item.type"
            @blur="$v.item.type.$touch()"
            @input="$v.item.type.$touch()"
            @change="onTypeChanged"
            outlined
          />
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            outlined
            :error-messages="targetUrlErrors"
            :label="$t('targetUrl')"
            @blur="$v.item.targetUrl.$touch()"
            @input="$v.item.targetUrl.$touch()"
            required
            v-model="item.targetUrl"
            v-if="isTargetUrl"
          />
          <v-select
            :error-messages="targetPageErrors"
            :label="$t('targetPage')"
            required
            item-value="@id"
            :items="pages"
            v-model="item.targetPage"
            @blur="$v.item.targetPage.$touch()"
            @input="$v.item.targetPage.$touch()"
            outlined
            v-if="isTargetPage"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.title }}
            </template>
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title>
                  <template>
                    {{ data.item.title }}
                  </template>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            outlined
            :error-messages="entryOrderErrors"
            :label="$t('entryOrder')"
            @blur="$v.item.entryOrder.$touch()"
            @input="$v.item.entryOrder.$touch()"
            required
            v-model.number="item.entryOrder"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-checkbox
            :error-messages="enabledErrors"
            :label="$t('enabled')"
            @blur="$v.item.enabled.$touch()"
            @input="$v.item.enabled.$touch()"
            v-model="item.enabled"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import {validationMixin} from "vuelidate";
import {required, integer} from "vuelidate/lib/validators";
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";

export default {
  name: "MenuEntryForm",
  mixins: [validationMixin],
  components: {},
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },
    initialValues: {
      type: Object,
      default: () => {
      },
    },
    refForm: {
      type: String,
      required: true,
    },
    parent: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      name: null,
      enabled: false,
      typeValues: [
        {text: this.$t("Page"), value: 0},
        {text: this.$t("URL"), value: 1},
        {text: this.$t("URLExternalBlank"), value: 2},
      ],
      isTargetUrl: false,
      isTargetPage: false,
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    titleErrors() {
      const errors = [];

      if (!this.$v.item.title.$dirty) {
        return errors;
      }

      has(this.violations, "title") && errors.push(this.violations.name);

      !this.$v.item.title.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.item.type.$dirty) {
        return errors;
      }

      has(this.violations, "type") && errors.push(this.violations.type);

      !this.$v.item.type.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, "enabled") && errors.push(this.violations.enabled);
      return errors;
    },
    targetUrlErrors() {
      const errors = [];

      if (!this.$v.item.targetUrl.$dirty) {
        return errors;
      }

      has(this.violations, "targetUrl") && errors.push(this.violations.targetUrl);
      return errors;
    },
    targetPageErrors() {
      const errors = [];

      if (!this.$v.item.targetPage.$dirty) {
        return errors;
      }

      has(this.violations, "targetPage") && errors.push(this.violations.targetPage);
      return errors;
    },
    entryOrderErrors() {
      const errors = [];

      if (!this.$v.item.entryOrder.$dirty) {
        return errors;
      }

      has(this.violations, "entryOrder") && errors.push(this.violations.entryOrder);
      return errors;
    },
    violations() {
      return this.errors || {};
    },
    ...mapFields("page", {
      pages: "selectItems",
    }),
    pageItems() {
      return this.pages ? this.pages : null;
    },
  },
  methods: {
    onTypeChanged(type) {
      if ([1, 2].includes(type)) {
        this.isTargetUrl = true;
        this.isTargetPage = false;
      } else if (0 === type) {
        this.isTargetPage = true;
        this.isTargetUrl = false;
      } else {
        this.isTargetUrl = false;
        this.isTargetPage = false;
      }
    },
    ...mapActions({
      pagesGetSelectedItems: "page/fetchSelectItems",
    }),
  },
  validations: {
    item: {
      title: {
        required,
      },
      type: {
        required,
      },
      targetUrl: {},
      targetPage: {},
      enabled: {},
      entryOrder: {
        required,
        integer,
      },
    },
  },
  mounted() {
    this.pagesGetSelectedItems();
    this.onTypeChanged(this.item.type);
  },
};
</script>
