import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingMenuEntries: [],
    };
  },
  computed: {
    ...mapFields("menuEntry", {
      menuEntryIds: "allIds",
      menuEntryIdsCached: "cacheAllIds",
    }),
    ...mapGetters("menuEntry", {
      menuEntryItems: 'list',
      findMenuEntry: "find",
    }),
  },
  methods: {
    ...mapActions("menuEntry", {
      retrieveMenuEntry: "load",
      fetchAllMenuEntries: 'fetchAll',
      updateMenuEntry: "update",
      menuEntryActions: "action",
    }),
    resolveMenuEntry(itemId) {
      if (!this.isMenuEntryLoaded(itemId) && !this.loadingMenuEntries.includes(itemId)) {
        this.loadingMenuEntries.push(itemId);
        this.retrieveMenuEntry(decodeURIComponent(itemId));
      }
      return this.findMenuEntry(itemId);
    },
    resolveMenuEntries(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveMenuEntry(itemId);
      }).filter(e => e);
    },
    getMenuEntryItem(menuEntryIri, type) {
      if (menuEntryIri !== null) {
        let menuEntry = this.resolveMenuEntry(menuEntryIri);
        if (menuEntry) {
          return menuEntry[type];
        }
      }
      return '';
    },
    isMenuEntryLoaded(itemId) {
      return this.menuEntryIdsCached.includes(itemId);
    },
  }
};
